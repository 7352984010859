// src/components/PropertyCardWithBeforeAfter.js

import React, { useEffect } from 'react';
import '../styles/PropertyCardWithBeforeAfter.css';
import { motion } from 'framer-motion';

const PropertyCardWithBeforeAfter = ({ before, after }) => {
  useEffect(() => {
    // Cleanup object URLs when component unmounts
    return () => {
      before.forEach((img) => URL.revokeObjectURL(img.src));
    };
  }, [before]);

  return (
    <motion.div 
      className="property-card-with-before-after"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h3>Before and After</h3>
      <div className="comparison-section">
        <div className="comparison">
          <div className="image-container">
            {before.map((img, index) => (
              <div style={{ flexDirection: 'column' }}>
              <img 
                key={index} 
                src={img.src} 
                alt={`Before ${index + 1}`} 
                className="comparison-image" 
              />
              <p className="description">{img.description}</p></div>
            ))}
          </div>
          {/* <p className="description">{beforeDescription}</p> */}
          {/* <p className="price">Price: ${before.price}</p> Display Price */}
        </div>
        <div className="comparison">
          <div className="image-container">
            {after.map((img, index) => (
              <>
              <img 
                key={index} 
                src={img.src.src} 
                alt={`After ${index + 1}`} 
                className="comparison-image" 
              />
              <a className='download-btn' href={img.src.src} download>Download</a>

              <p className="description">{img.src.description}</p></>
            ))}
          </div>
          {/* <p className="price">Price: ${after.price}</p> Display Price */}
        </div>
      </div>
    </motion.div>
  );
};

export default PropertyCardWithBeforeAfter;
