// src/context/AuthContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  getIdToken,
  updateProfile
} from 'firebase/auth';
import { auth } from '../firebase';

// Create the Auth Context
const AuthContext = createContext();

// Export useAuth hook for easier consumption
export const useAuth = () => useContext(AuthContext);

// Auth Provider Component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Sign Up Function
  const signUp = async (email, password, username) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    console.log(userCredential);
    // Update displayName
    await updateProfile(userCredential.user, {
      displayName: username
    });
    console.log('updated displayName')
    // Send Email Verification
    await sendEmailVerification(userCredential.user);
    console.log('sent email verification')
    return userCredential;
  };

  // Sign In Function
  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  // Sign Out Function
  const logout = () => {
    setCurrentUser(null);
    return signOut(auth);
  };

  // Google Sign-In Function
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  // Monitor Auth State
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user?.emailVerified) {
        setCurrentUser(user);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []); 

  const getIdTokenMethod = (user, forceRefresh = false) => {
    return getIdToken(user, forceRefresh);
  };

  const value = {
    currentUser,
    signUp,
    signIn,
    logout,
    signInWithGoogle,
    getIdToken: getIdTokenMethod,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
