import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Cart.css';
import { useCart } from '../context/CardContext';
import { motion } from 'framer-motion';

const Cart = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();

  const totalPrice = cartItems.reduce((acc, item) => acc + item.price, 0);

  return (
    <motion.div 
      className="cart"
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      transition={{ type: 'spring', stiffness: 50 }}
    >
      <h2>Your Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          <ul className="cart__list">
            {cartItems.map((item, index) => (
              <li key={index} className="cart__item">
                <img src={item.image} alt={item.title} className="cart__item-image" />
                <div className="cart__item-details">
                  <h3>{item.title}</h3>
                  <p>${item.price}</p>
                  <button onClick={() => removeFromCart(index)} className="cart__remove-button">
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="cart__summary">
            <h3>Total: ${totalPrice}</h3>
            <div className="cart__actions">
              <button onClick={clearCart} className="cart__clear-button">Clear Cart</button>
              <Link to="/checkout" className="cart__checkout-button">Proceed to Checkout</Link>
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Cart;
