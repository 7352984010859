// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: var(--primary-color);
  color: var(--muted-text);
  text-align: center;
  padding: 1.5rem 2rem;
  /* position: sticky; */
  bottom: 0;
  width: 100%;
  box-shadow: 0 -4px 6px rgba(255, 255, 255, 0.5);
  font-family: var(--font-secondary);
}

.footer__social {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.footer__social a {
  color: var(--muted-text);
  font-size: 1.2rem;
  transition: color var(--transition-speed) ease;
}

.footer__social a:hover {
  color: var(--secondary-accent);
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,wBAAwB;EACxB,kBAAkB;EAClB,oBAAoB;EACpB,sBAAsB;EACtB,SAAS;EACT,WAAW;EACX,+CAA+C;EAC/C,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,8CAA8C;AAChD;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".footer {\n  background-color: var(--primary-color);\n  color: var(--muted-text);\n  text-align: center;\n  padding: 1.5rem 2rem;\n  /* position: sticky; */\n  bottom: 0;\n  width: 100%;\n  box-shadow: 0 -4px 6px rgba(255, 255, 255, 0.5);\n  font-family: var(--font-secondary);\n}\n\n.footer__social {\n  display: flex;\n  justify-content: center;\n  gap: 1.5rem;\n  margin-bottom: 1rem;\n}\n\n.footer__social a {\n  color: var(--muted-text);\n  font-size: 1.2rem;\n  transition: color var(--transition-speed) ease;\n}\n\n.footer__social a:hover {\n  color: var(--secondary-accent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
