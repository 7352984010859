// src/components/ImageModal.js

import React from 'react';
import '../styles/ImageModal.css';
import { motion } from 'framer-motion';

const ImageModal = ({ image, description, price, uploader, onClose, onPrev, onNext }) => {
  return (
    <motion.div 
      className="modal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose} // Close modal when clicking outside the content
    >
      <motion.div 
        className="modal-content"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        <span className="close" onClick={onClose}>&times;</span>
        <div className="modal-inner-content">
          <button className="arrow left-arrow" onClick={onPrev}>&#10094;</button>
          <img src={image} alt="Property" className="modal-image" />
          <button className="arrow right-arrow" onClick={onNext}>&#10095;</button>
          <div className="modal-description">
            {/* <h2 style={{ color: '#263238' }}>Price: ${price}</h2> */}
            <p style={{ color: '#263238' }}>{description}</p>
            <p className="uploader" style={{ color: '#263238' }}>Posted by: {uploader}</p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ImageModal;
