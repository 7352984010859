// src/components/PropertyUploadForm.js

import React, { useState, useEffect, useContext } from 'react';
import '../styles/PropertyUploadForm.css';
import { motion } from 'framer-motion';
import { FiUpload } from 'react-icons/fi';
import { PropertyContext } from '../context/PropertyContext'; // Import PropertyContext
import axios from 'axios';
import { auth } from '../firebase'; // Import Firebase auth

const PropertyUploadForm = ({ onEnhancedData, onLoading, onError, onPost }) => {
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [enhancedData, setEnhancedData] = useState(null);

  const { addProperty } = useContext(PropertyContext); // Use addProperty from context

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleAnalyze = async () => {
    if (images.length === 0 || description.trim() === '' ) {
      onError('Please upload images and provide a description');
      return;
    }

    onLoading(true);
    onError(null);

    try {
      // Get Firebase ID token
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated.');
      }
      const token = await user.getIdToken(/* forceRefresh */ true);

      // Prepare form data
      const formData = new FormData();
      formData.append('description', description);
      formData.append('price', price);
      images.forEach((image) => {
        formData.append('files', image);
      });

      // Send POST request to FastAPI 
      const response = await axios.post('https://listing-genie-backend-fzd3d2eygfhfbgf9.centralindia-01.azurewebsites.net/listings/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      const property = response.data;
  
      setEnhancedData({ before: property['images'], after: property['enhanced_images'] });
      onEnhancedData({ before: property['images'], after: property['enhanced_images'] });
      addProperty(property);
    } catch (err) {
      console.error(err);
      onError(err.response?.data?.detail || 'An unexpected error occurred. Please try again.');
    } finally {
      onLoading(false);
    }
  };

  // Cleanup object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      images.forEach((img) => URL.revokeObjectURL(img));
    };
  }, [images]);

  return (
    <div className="property-upload-form">
      {/* Image Upload Section */}
      <motion.div 
        className="form-group"
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.3 }}
      >
        <label htmlFor="images">
          <FiUpload size={24} />
          Upload Images
        </label>
        <input 
          type="file" 
          id="images" 
          multiple 
          accept="image/*" 
          onChange={handleImageChange} 
        />
        <div className="image-previews">
          {images.map((image, index) => (
            <img 
              key={index} 
              src={URL.createObjectURL(image)} 
              alt={`Preview ${index + 1}`} 
              className="image-preview"
            />
          ))}
        </div>
      </motion.div>

      {/* Description Upload Section */}
      <motion.div 
        className="form-group"
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.3 }}
      >
        <label htmlFor="description">Property Description</label>
        <textarea 
          id="description" 
          value={description} 
          onChange={handleDescriptionChange} 
          placeholder="Enter property details..." 
          rows="5"
        ></textarea>
      </motion.div>

      {/* Price Input Section */}
      <motion.div 
        className="form-group"
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.3 }}
      >
        {/* <label htmlFor="price">Price ($)</label> */}
        {/* <input 
          type="number" 
          id="price" 
          value={price} 
          onChange={handlePriceChange} 
          placeholder="Enter property price..." 
          min="0"
        /> */}
      </motion.div>

      {/* Analyze & Enhance Button */}
      <motion.button 
        className="analyze-button"
        onClick={handleAnalyze}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Analyze & Enhance
      </motion.button>

      {/* Display Enhanced Data */}
      {/*   */}
    </div>
  );
};

export default PropertyUploadForm;
