import React from 'react';
import '../styles/Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Footer = () => {
  const iconVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { delay: 0.5, duration: 0.5 } 
    },
    hover: { 
      scale: 1.2, 
      color: '#00d8ff', 
      transition: { duration: 0.3 } 
    },
  };

  return (
    <footer className="footer">
      <motion.div 
        className="footer__social"
        initial="hidden"
        animate="visible"
      >
        <motion.a href="#" variants={iconVariants} whileHover="hover">
          <FaFacebookF />
        </motion.a>
        <motion.a href="#" variants={iconVariants} whileHover="hover">
          <FaTwitter />
        </motion.a>
        <motion.a href="#" variants={iconVariants} whileHover="hover">
          <FaInstagram />
        </motion.a>
        <motion.a href="#" variants={iconVariants} whileHover="hover">
          <FaLinkedinIn />
        </motion.a>
      </motion.div>
      <p>© {new Date().getFullYear()} PropTech. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
