import React, { useState } from 'react';
import '../styles/Contact.css';
import { motion } from 'framer-motion';
import { FiSend } from 'react-icons/fi';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ 
      ...formData, 
      [e.target.name]: e.target.value 
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send to API)
    console.log(formData);
    alert('Message sent!');
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  const formVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1, 
      transition: { staggerChildren: 0.2 } 
    },
  };

  const fieldVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div 
      className="contact"
      initial="hidden"
      animate="visible"
      variants={formVariants}
    >
      <h2>Contact Us</h2>
      <form className="contact__form" onSubmit={handleSubmit}>
        <motion.label variants={fieldVariants}>
          Name
          <input 
            type="text" 
            name="name" 
            value={formData.name}
            onChange={handleChange}
            required 
            placeholder="Your Name"
          />
        </motion.label>
        <motion.label variants={fieldVariants}>
          Email
          <input 
            type="email" 
            name="email" 
            value={formData.email}
            onChange={handleChange}
            required 
            placeholder="your.email@example.com"
          />
        </motion.label>
        <motion.label variants={fieldVariants}>
          Message
          <textarea 
            name="message" 
            value={formData.message}
            onChange={handleChange}
            required 
            placeholder="Your message..."
          />
        </motion.label>
        <motion.button 
          type="submit" 
          className="contact__button"
          whileTap={{ scale: 0.95 }}
        >
          <FiSend size={20} style={{ marginRight: '0.5rem' }} />
          Send Message
        </motion.button>
      </form>
    </motion.div>
  );
};

export default Contact;
