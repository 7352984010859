// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-property {
    padding: 3rem 2rem;
    background-color: transparent;
    color: var(--text-color);
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .post-property h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: var(--accent-color);
    text-shadow: 0 0 5px var(--secondary-accent);
  }
    
  .loading {
    color: #00d8ff;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  
  .error {
    color: #ff4d4d;
    font-size: 1.2rem;
    margin-top: 1rem;
  }


  `, "",{"version":3,"sources":["webpack://./src/styles/PostProperty.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,wBAAwB;IACxB,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,0BAA0B;IAC1B,4CAA4C;EAC9C;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".post-property {\n    padding: 3rem 2rem;\n    background-color: transparent;\n    color: var(--text-color);\n    max-width: 1000px;\n    margin: 0 auto;\n    text-align: center;\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n  }\n  \n  .post-property h2 {\n    font-size: 2.5rem;\n    margin-bottom: 2rem;\n    color: var(--accent-color);\n    text-shadow: 0 0 5px var(--secondary-accent);\n  }\n    \n  .loading {\n    color: #00d8ff;\n    font-size: 1.2rem;\n    margin-top: 1rem;\n  }\n  \n  .error {\n    color: #ff4d4d;\n    font-size: 1.2rem;\n    margin-top: 1rem;\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
