import React from 'react';
import '../styles/AboutUs.css';
import { motion } from 'framer-motion';
import { FaBuilding, FaUsers, FaGlobe } from 'react-icons/fa';

const AboutUs = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1, 
      transition: { 
        staggerChildren: 0.3 
      } 
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5 } 
    },
  };

  return (
    <motion.div 
      className="about-us"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <h2>About Us</h2>
      <p>
        Welcome to RealEstate, your number one source for all things property. We're dedicated to providing you the best of real estate listings, with a focus on dependability, customer service, and uniqueness.
      </p>
      <div className="about-us__cards">
        <motion.div className="about-us__card" variants={cardVariants}>
          <FaBuilding size={40} color="#00d8ff" />
          <h3>Our Mission</h3>
          <p>To revolutionize the real estate market with innovative solutions and unparalleled customer service.</p>
        </motion.div>
        <motion.div className="about-us__card" variants={cardVariants}>
          <FaUsers size={40} color="#00d8ff" />
          <h3>Our Team</h3>
          <p>A diverse group of professionals committed to helping you find your dream property.</p>
        </motion.div>
        <motion.div className="about-us__card" variants={cardVariants}>
          <FaGlobe size={40} color="#00d8ff" />
          <h3>Global Presence</h3>
          <p>Serving clients worldwide with a vast network of properties and partners.</p>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
