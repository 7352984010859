// src/pages/PostProperty.js

import React, { useState, useContext } from 'react';
import '../styles/PostProperty.css';
import PropertyUploadForm from '../components/PropertyUploadForm';
import PropertyCardWithBeforeAfter from '../components/PropertyCardWithBeforeAfter';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner'; // Optional: For loading indicators
import { PropertyContext } from '../context/PropertyContext'; // Import PropertyContext
import { useAuth } from '../context/AuthContext'; // Import useAuth
import axios from 'axios'; // Ensure axios is installed and imported

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://your-fastapi-backend.azurewebsites.net';

const PostProperty = () => {
  const [enhancedData, setEnhancedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { addProperty } = useContext(PropertyContext); // Use addProperty from context
  const { currentUser, getIdToken } = useAuth(); // Get current user and a method to get the token

  const handleEnhancedData = (data) => {
    setEnhancedData(data);
  };

  const handleLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const handleError = (err) => {
    setError(err);
  };

  const handlePost = async () => {
    if (!enhancedData) {
      setError('No enhanced data available to post.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Retrieve the Firebase JWT token
      const token = await getIdToken(currentUser, /* forceRefresh= */ true);

      // Prepare form data
      const formData = new FormData();
      formData.append('title', enhancedData.after.title);
      formData.append('description', enhancedData.before.description);
      formData.append('price', enhancedData.before.price);

      console.log('Token:', token);


      // Add the new property to the global context
      addProperty({
        id: enhancedData.id,
        title: enhancedData.title,
        // description: listing.description,
        price: enhancedData.price,
        images: enhancedData.before, // Original image URLs
        enhanced_images: enhancedData.after, // Enhanced image URLs
        // uploader: listing.uploader,
        // uploaderEmail: listing.uploaderEmail,
        // timestamp: listing.timestamp,
      });

      // Simulate successful post
      alert('Property posted successfully!');

      // Reset the form and enhanced data
      setEnhancedData(null);
      // Optionally, reset the PropertyUploadForm by lifting state or using refs
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError('Failed to post property. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Utility function to convert data URL to Blob
  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  return (
    <motion.div 
      className="post-property"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Post a New Property</h2>
      <PropertyUploadForm 
        onEnhancedData={handleEnhancedData} 
        onLoading={handleLoading} 
        onError={handleError} 
        onPost={handlePost} // Pass handlePost to the child
      />
      {loading && <Spinner />} {/* Optional: Display spinner during loading */}
      {error && <p className="error">{error}</p>}
      {enhancedData && (
        <PropertyCardWithBeforeAfter 
          before={enhancedData.before} 
          after={enhancedData.after} 
        />
      )}
    </motion.div>
  );
};

export default PostProperty;
