import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiShoppingCart } from 'react-icons/fi';
import { useCart } from '../context/CardContext';
import { useAuth } from '../context/AuthContext';
import '../styles/Navbar.css';
import { motion } from 'framer-motion';
import bgImg from '../assets/bg/shape1.png'

const Navbar = () => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  // State for handling scroll position and sticky header logic
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  const sceneEl = useRef(null); // Parallax reference for logo
  const backgroundEl = useRef(null); // Parallax reference for background

  // Scroll effect logic
  useEffect(() => {
    const header = document.querySelector(".navbar"); // Select navbar
    setHeaderTop(header.offsetTop); // Get header's initial top offset

    const handleScroll = () => {
      setScroll(window.scrollY); // Track scroll position
    };

    window.addEventListener("scroll", handleScroll); // Add scroll event listener

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up event listener on unmount
    };
  }, []);

  const { cartItems } = useCart();
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/'); // Redirect to home after logout
    } catch (err) {
      console.error(err);
    }
  };

  const navVariants = {
    hidden: { y: -100 },
    visible: { y: 0, transition: { type: 'spring', stiffness: 70 } },
  };

  const linkVariants = {
    hover: { scale: 1.1, color: '#00d8ff', transition: { duration: 0.3 } },
  };

  return (
    <>
      <motion.nav
        className="navbar"
        variants={navVariants}
        initial="hidden"
        animate="visible"
        style={{ position: scroll > headerTop ? "sticky" : "", transition: "position 0.3s, top 0.3s", backgroundColor: scroll > headerTop ? "white" : "transparent" }}
      >
        <div className="navbar__logo" ref={sceneEl}>
          <Link to="/">Listing Genie</Link>
        </div>
        <ul className="navbar__links">
          {/* <motion.li whileHover="hover" variants={linkVariants}>
            <Link to="/">Home</Link>
          </motion.li> */}
          {/* <motion.li whileHover="hover" variants={linkVariants}>
            <Link to="/shop">Shop</Link>
          </motion.li> */}
          {/* <motion.li whileHover="hover" variants={linkVariants}>
            <Link to="/about-us">About Us</Link>
          </motion.li>
          <motion.li whileHover="hover" variants={linkVariants}>
            <Link to="/contact">Contact</Link>
          </motion.li>
          {currentUser && (
            <motion.li whileHover="hover" variants={linkVariants}>
              <Link to="/post-property">Post Property</Link>
            </motion.li>
          )} */}
        </ul>
        {/* <div className="navbar__cart">
          <Link to="/cart">
            <FiShoppingCart size={24} />
            {cartItems.length > 0 && <span className="cart__count">{cartItems.length}</span>}
          </Link>
        </div> */}
        <div className="navbar__auth">
          {currentUser ? (
            <motion.button
              className="logout-button"
              onClick={handleLogout}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Logout
            </motion.button>
          ) : (
            <>
              <motion.button
                className="login-button"
                onClick={() => navigate('/sign-in')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Sign In
              </motion.button>
              <motion.button
                className="signup-button"
                onClick={() => navigate('/sign-up')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Sign Up
              </motion.button>
            </>
          )}
        </div>
      </motion.nav>
    </>
  );
};

export default Navbar;
