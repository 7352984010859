// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
  padding: 3rem 2rem;
  background-color: transparent;
  color: var(--text-color);
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.about-us h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--accent-color);
  text-shadow: 0 0 5px var(--secondary-accent);
}

.about-us p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  color: var(--muted-text);
}

.about-us__cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.about-us__card {
  background: transparent;
  border-radius: 12px;
  padding: 2rem;
  width: 300px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.about-us__card:hover {
  transform: translateY(-5px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.5);
}

.about-us__card h3 {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: var(--accent-color);
}

.about-us__card p {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: var(--muted-text);
}
`, "",{"version":3,"sources":["webpack://./src/styles/AboutUs.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,wBAAwB;EACxB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,0BAA0B;EAC1B,4CAA4C;AAC9C;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,SAAS;AACX;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,yCAAyC;EACzC,2FAA2F;AAC7F;;AAEA;EACE,2BAA2B;EAC3B,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":[".about-us {\n  padding: 3rem 2rem;\n  background-color: transparent;\n  color: var(--text-color);\n  max-width: 1000px;\n  margin: 0 auto;\n  text-align: center;\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\n.about-us h2 {\n  font-size: 2.5rem;\n  margin-bottom: 1.5rem;\n  color: var(--accent-color);\n  text-shadow: 0 0 5px var(--secondary-accent);\n}\n\n.about-us p {\n  font-size: 1.1rem;\n  margin-bottom: 2rem;\n  line-height: 1.6;\n  color: var(--muted-text);\n}\n\n.about-us__cards {\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n  gap: 2rem;\n}\n\n.about-us__card {\n  background: transparent;\n  border-radius: 12px;\n  padding: 2rem;\n  width: 300px;\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);\n  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;\n}\n\n.about-us__card:hover {\n  transform: translateY(-5px);\n  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.5);\n}\n\n.about-us__card h3 {\n  margin-top: 1rem;\n  font-size: 1.5rem;\n  color: var(--accent-color);\n}\n\n.about-us__card p {\n  margin-top: 0.5rem;\n  font-size: 1rem;\n  color: var(--muted-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
