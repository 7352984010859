import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/Home.css';
import img1 from '../assets/1.png'; // You can change this to your actual image path
import img3 from '../assets/3.png'; // You can change this to your actual image path
import img2 from '../assets/2.png'; // You can change this to your actual image path
import homeImg from '../assets/ai.png'; // You can change this to your actual image path

const Home = () => {

  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const heroVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 1, delay: 0.5, type: 'spring', stiffness: 50 } 
    },
  };

  const buttonVariants = {
    hover: { 
      scale: 1.05, 
      boxShadow: '0px 0px 8px rgb(0, 216, 255)', 
      transition: { yoyo: Infinity } 
    },
  };

  return (
    <div>
    <motion.div 
      className="home"
      initial="hidden"
      animate="visible"
      variants={heroVariants}
    >
      <div className="home__hero">
        <motion.h1
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          Leveraging AI to ensure platform compliance, better visibility and enhanced property listing
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          Explore the best real estate listings with state-of-the-art features.
        </motion.p>
        <div className="home__buttons">
          {currentUser ? (<motion.button
            className="home__cta"
            onClick={() => navigate('/post-property')}
            variants={buttonVariants}
            whileHover="hover"
          >
            Try it now
          </motion.button>) : (
            <motion.button
            className="home__cta"
            onClick={() => navigate('/sign-in')}
            variants={buttonVariants}
            whileHover="hover"
          >
            Sign In
          </motion.button>
          )}
          <motion.button
            className="home__cta"
            onClick={() => navigate('/contact')}
            variants={buttonVariants}
            whileHover="hover"
            style={{ backgroundColor: 'white', color: 'black' }}
          >
            Contact Us
          </motion.button>
        </div>
      </div>
      <div className="home__image">
        <motion.img
          src={homeImg} /* Replace with your actual image path */
          alt="Real estate listing"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        />
      </div>
      
    </motion.div>
    {/* Features Section */}
{/* Features Section */}
<div className="features">
  <h2 style={{ fontSize: '17px', color: '#AAABBF' }}>FEATURES</h2>
  <h4 style={{ fontSize: '30px' }}>
  We are <span style={{ color: '#59E3A7', fontSize: '30px' }}>different</span> because...
</h4>

        <div className="feature">
          <div className="feature-text">
            {/* <h3>Feature 1 Title</h3> */}
            <p style={{ fontSize: '30px', fontWeight: 'bold' }}>The first and foremost feature of listing genie is that it validates the input from user by analysing the image and description of the property.</p>
          </div>
          <div className="feature-image">
            <img src={img1} alt="Feature 1" />
          </div>
        </div>
        <div className="reverse">
          <div className="feature"> {/* Reverse order for the second feature */}
            <div className="feature-image">
              <img src={img2} alt="Feature 2" />
            </div>
            <div className="feature-text" style={{ textAlign: 'left', marginLeft: '60px' }}>
              {/* <h3>Feature 2 Title</h3> */}
              <p style={{ fontSize: '30px', fontWeight: 'bold' }}>After validation is passed, the image is enhanced to improve the quality of the image as well as the user experience. Further the description is analysed and improved to make it more appealing to the user.</p>
            </div>
          </div>
        </div>
        <div className="feature">
          <div className="feature-text">
            {/* <h3>Feature 3 Title</h3> */}
            <p style={{ fontSize: '30px', fontWeight: 'bold' }}>If the validation fails, the user is prompted to enter the details again. The user is also given the option to upload a new image or change the description of the property.</p>
          </div>
          <div className="feature-image">
            <img src={img3} alt="Feature 3"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
