// src/pages/SignUp.js

import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../styles/SignUp.css';
import { auth } from '../firebase';
import googleImg from '../assets/google.png'

const SignUp = () => {
  const { signUp, signInWithGoogle } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      const userCredential = await signUp(email, password, username);
      setMessage('Sign up successful! Please verify your email before logging in.');
      // Optionally, redirect to sign in page
      navigate('/sign-in');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setError('');
    setMessage('');
    try {
      await signInWithGoogle();
      const response = await fetch('https://listing-genie-backend-fzd3d2eygfhfbgf9.centralindia-01.azurewebsites.net/auth/register', 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.currentUser.getIdToken()}`
            },
            body: JSON.stringify({user: {
                email: auth.currentUser.email,
                username: auth.currentUser.displayName},
                current_user: auth.currentUser
            })
            }
    )
    const data = await response.json();
    console.log(data);
      navigate('/'); // Redirect to home after successful sign-in
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <motion.div 
      className="sign-up-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Sign Up</h2>
      {error && <p className="error">{error}</p>}
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit} className="sign-up-form">
        <label>
          Username:
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
        </label>
        <label>
          Email:
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </label>
        <label>
          Password:
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </label>
        <button type="submit" className="submit-button">Sign Up</button>
      </form>
      <p>OR</p>
      <button onClick={handleGoogleSignIn} className="google-button">
        <img style={{ height: '20px', marginRight: '20px' }} src={googleImg} alt="Google Logo" />
        Continue with Google
      </button>
      <p>
        Already have an account? <Link to="/sign-in">Sign In</Link>
      </p>
    </motion.div>
  );
};

export default SignUp;
