import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Shop from './pages/Shop';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import PostProperty from './pages/PostProperty'; // Import the new page
import { CartProvider } from './context/CardContext';
import { PropertyProvider } from './context/PropertyContext'; // Import PropertyProvider
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import bgImg from './assets/bg/shape1.png'

function App() {
  return (
    <AuthProvider> {/* Provide AuthContext to the app */}
      <CartProvider>
        <PropertyProvider> {/* Provide PropertyContext to the app */}
          <Router>
            <div className="app" style={{ backgroundImage: `url(${bgImg})`, backgroundRepeat: 'no-repeat' }}>
              <Navbar />
              <div className="content">
                <Routes>
                  <Route path="/" element={<Home />} />
                  {/* <Route path="/shop" element={<Shop />} /> */}
                  {/* <Route path="/about-us" element={<AboutUs />} /> */}
                  {/* <Route path="/contact" element={<Contact />} /> */}
                  {/* <Route path="/cart" element={<Cart />} /> */}
                  {/* <Route path="/checkout" element={<Checkout />} /> */}
                  <Route path="/post-property" element={
                    <PrivateRoute>
                      <PostProperty />
                    </PrivateRoute>
                  } /> {/* Protected Route */}
                  <Route path="/sign-in" element={<SignIn />} /> {/* Sign In Route */}
                  <Route path="/sign-up" element={<SignUp />} /> {/* Sign Up Route */}
                </Routes>
              </div>
              <Footer />
            </div>
          </Router>
        </PropertyProvider>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
