// src/context/PropertyContext.js

import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const PropertyContext = createContext();

// Create the provider component
export const PropertyProvider = ({ children }) => {
  const [properties, setProperties] = useState([]);

  // Load properties from localStorage on initial render
  useEffect(() => {
    // Create an async function inside useEffect
    const fetchProperties = async () => {
      try {
        const response = await fetch('https://listing-genie-backend-fzd3d2eygfhfbgf9.centralindia-01.azurewebsites.net/listings/?skip=0&limit=10', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log('Properties fetched:', data);
          setProperties(data);
          console.log('Properties fetched:', data);
        } else {
          console.error('Failed to fetch properties.');
        }
      } catch (err) {
        console.error('An error occurred while fetching properties:', err);
      }
    };

    fetchProperties(); // Call the async function
  }, []);


  // Function to add a new property
  const addProperty = (property) => {
    setProperties((prevProperties) => [...prevProperties, property]);
  };

  return (
    <PropertyContext.Provider value={{ properties, addProperty }}>
      {children}
    </PropertyContext.Provider>
  );
};
