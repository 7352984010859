import React, { useState } from 'react';
import '../styles/Checkout.css';
import { useCart } from '../context/CardContext';
import { motion } from 'framer-motion';
import { FiCheckCircle } from 'react-icons/fi';

const Checkout = () => {
  const { cartItems, clearCart } = useCart();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    payment: '',
  });

  const handleChange = (e) => {
    setFormData({ 
      ...formData, 
      [e.target.name]: e.target.value 
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process checkout (e.g., send to API)
    console.log('Order placed:', { formData, cartItems });
    alert('Order placed successfully!');
    clearCart();
    setFormData({
      name: '',
      email: '',
      address: '',
      payment: '',
    });
  };

  const formVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { duration: 0.5 } 
    },
  };

  const fieldVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { delay: 0.2, duration: 0.5 } 
    },
  };

  const buttonVariants = {
    hover: { scale: 1.05, background: '#00d8ff', transition: { duration: 0.3 } },
    tap: { scale: 0.95 },
  };

  return (
    <motion.div 
      className="checkout"
      initial="hidden"
      animate="visible"
      variants={formVariants}
    >
      <h2>Checkout</h2>
      <form className="checkout__form" onSubmit={handleSubmit}>
        <motion.label variants={fieldVariants}>
          Name
          <input 
            type="text" 
            name="name" 
            value={formData.name}
            onChange={handleChange}
            required 
            placeholder="Your Full Name"
          />
        </motion.label>
        <motion.label variants={fieldVariants}>
          Email
          <input 
            type="email" 
            name="email" 
            value={formData.email}
            onChange={handleChange}
            required 
            placeholder="your.email@example.com"
          />
        </motion.label>
        <motion.label variants={fieldVariants}>
          Address
          <input 
            type="text" 
            name="address" 
            value={formData.address}
            onChange={handleChange}
            required 
            placeholder="Your Address"
          />
        </motion.label>
        <motion.label variants={fieldVariants}>
          Payment Details
          <input 
            type="text" 
            name="payment" 
            value={formData.payment}
            onChange={handleChange}
            required 
            placeholder="Card Number"
          />
        </motion.label>
        <h3>Total: ${cartItems.reduce((acc, item) => acc + item.price, 0).toLocaleString()}</h3>
        <motion.button 
          type="submit" 
          className="checkout__button"
          variants={buttonVariants}
          whileHover="hover"
          whileTap="tap"
        >
          <FiCheckCircle size={20} style={{ marginRight: '0.5rem' }} />
          Place Order
        </motion.button>
      </form>
    </motion.div>
  );
};

export default Checkout;
