import React, { useContext, useState } from 'react';
import '../styles/Shop.css';
import { PropertyContext } from '../context/PropertyContext'; // Import PropertyContext
import { motion } from 'framer-motion';
import ImageModal from '../components/ImageModal'; // Create a new ImageModal component

const Shop = () => {
  const { properties } = useContext(PropertyContext); // Access properties from context
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (property, index) => {
    setSelectedProperty(property);
    setCurrentImageIndex(index);
  };

  const closeModal = () => {
    setSelectedProperty(null);
    setCurrentImageIndex(0);
  };

  const changeImage = (direction) => {
    if (!selectedProperty) return;
    let newIndex = currentImageIndex + direction;
    if (newIndex < 0) {
      newIndex = selectedProperty.images.length - 1;
    } else if (newIndex >= selectedProperty.images.length) {
      newIndex = 0;
    }
    setCurrentImageIndex(newIndex);
  };

  return (
    <motion.div 
      className="shop"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Available Properties</h2>
      {properties.length === 0 ? (
        <p>No properties available. Be the first to post one!</p>
      ) : (
        <div className="property-list">
          {properties.map((property, index) => (
            <motion.div 
              key={index} 
              className="property-card"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="property-images">
                {property.enhanced_images.map((img, imgIndex) => (
                  <img 
                    key={imgIndex} 
                    src={img.src.src} 
                    alt={`Property ${index + 1} Image ${imgIndex + 1}`} 
                    className="property-image"
                    onClick={() => openModal(property, imgIndex)}
                  />
                ))}
              </div>
              <div className="property-details">
                <p className="property-description">{property.title}</p>
                <p className="property-price">${property.price}</p>
                <p className="property-uploader">Posted by: {property.username}</p>
              </div>
            </motion.div>
          ))}
        </div>
      )}
      {selectedProperty && (
        <ImageModal 
          image={selectedProperty.enhanced_images[currentImageIndex].src.src} 
          description={selectedProperty.enhanced_images[currentImageIndex].src.description}
          price={selectedProperty.price}
          uploader={selectedProperty.username}
          onClose={closeModal}
          onPrev={() => changeImage(-1)}
          onNext={() => changeImage(1)}
        />
      )}
    </motion.div>
  );
};

export default Shop;
