// src/pages/SignIn.js

import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../styles/SignIn.css';
import { auth } from '../firebase';
import googleImg from '../assets/google.png';
import { getIdToken } from 'firebase/auth';

const SignIn = () => {
  const { signIn, signInWithGoogle } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signIn(email, password);
      if (userCredential.user.emailVerified) {

        navigate('/'); // Redirect to home after successful sign-in
        window.location.reload();
      } else {
        setError('Please verify your email before logging in.');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setError('');
    try {
      await signInWithGoogle();
      const response = await fetch('https://listing-genie-backend-fzd3d2eygfhfbgf9.centralindia-01.azurewebsites.net/auth/register', 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getIdToken(auth.currentUser)}`
            },
            body: JSON.stringify({user: {
                email: auth.currentUser.email,
                username: auth.currentUser.displayName},
                current_user: auth.currentUser
            })
            }
    )
    const data = await response.json();
    console.log(data);
      navigate('/'); // Redirect to home after successful sign-in
    } catch (err) {
      if(err.message === 'Firebase: Error (auth/email-already-in-use).') {
      setError("Email already in use. Please sign in with your email and password.");
    }
      else {
        setError(err.message);
    }
    }
  };

  return (
    <motion.div 
      className="sign-in-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Sign In</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit} className="sign-in-form">
        <label>
          Email:
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </label>
        <label>
          Password:
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </label>
        <button type="submit" className="submit-button">Sign In</button>
      </form>
      <p>OR</p>
      <button onClick={handleGoogleSignIn} className="google-button">
        <img style={{ height: '20px', marginRight: '20px' }} src={googleImg} alt="Google Logo" />
        Continue with Google
      </button>
      <p>
        Don't have an account? <Link to="/sign-up">Sign Up</Link>
      </p>
    </motion.div>
  );
};

export default SignIn;
